<template>
  <div>
    <v-btn
      v-if="phoneNumber"
      :disabled="!phoneNumber || btnDisabled"
      :href="clickToDialProtocol"
      icon
      x-small
      @click.stop
    >
      <icon-tooltip
        :x-small="xsmall"
        :small="small"
        :normal="normal"
        :large="large"
        :x-large="xlarge"
        :icon-name="'i.Phone'"
        :tooltip-text="$t('t.ClickToDial')"
      />
    </v-btn>
    <span v-if="showPhoneNumber">{{phoneNumber}}</span>
  </div>
</template>

<script>
export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    clickToDialProtocol () {
      return `${this.$store?.getters?.currentUser?.settings?.clickToDialProtocol}:${this.phoneNumber}`
    }
  },
  props: {
    hideBtnOnEmpty: {
      type: Boolean,
      default: false
    },
    btnDisabled: Boolean,
    phoneNumber: {
      type: String
    },
    xsmall: Boolean,
    small: {
      type: Boolean,
      default: false
    },
    normal: {
      type: Boolean,
      default: true
    },
    large: Boolean,
    xlarge: Boolean,
    showPhoneNumber: {
      type: Boolean,
      default: true
    }
  }
}
</script>
